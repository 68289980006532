<template>
  <div>
    <Pane />

    <a-card class="container">
      <MonthTable
        :year="year"
        :month="month"
        :date="date"
        :name="user.userName"
        :loading="loading"
        :list="list"
        :disabled="disabled"
        @change="payload => list = payload "
        @changeDate="changeDate"
        v-if="currentYear === year && currentMonth === month"
      />
      <MonthTableDelay
        :year="year"
        :month="month"
        :date="date"
        :name="user.userName"
        :loading="loading"
        :list="list"
        @change="payload => list = payload "
        @changeDate="changeDate"
        v-else
      />

      <div class="center">
        <a-space>
          <a-button :loading="saveDraftLoading" @click="saveDraft">暂存</a-button>
          <a-button
            type="primary"
            :disabled="disabled || (currentYear !== year || currentMonth !== month)"
            :loading="saveLoading"
            @click="save"
          >提交计划</a-button>
          <a-button @click="$close($route.path)">关闭</a-button>
        </a-space>
      </div>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import MonthTable from "./components/month-table.vue";
import MonthTableDelay from "./components/month-table-delay.vue";

import { mapState } from "vuex";
import moment from "moment";

import request from "@/api/request";

function add(data) {
  return request({
    url: "/project-service/projectPlan/add",
    method: "post",
    data
  });
}

// 暂存
function addDraft(data) {
  return request({
    url: "/project-service/projectPlan/save",
    method: "post",
    data
  });
}

function checkUseable(data) {
  let path = "";
  if (data) {
    path = `/${data.year}/${data.month}`;
  }

  console.log("path", path);

  return request({
    url: "/project-service/projectPlan/checkAddMonthPlan" + path,
    method: "post"
  });
}

export default {
  mixins: [watermark],

  components: {
    MonthTable,
    MonthTableDelay
  },

  data() {
    return {
      currentYear: 0,
      currentMonth: 0,

      year: 0,
      month: 0,
      date: 0,

      loading: false,
      list: [],

      disabled: false,

      saveLoading: false,
      saveDraftLoading: false
    };
  },

  computed: {
    ...mapState("auth", ["user"])
  },

  mounted() {
    this.init();
  },

  methods: {
    changeDate({ year, month, date }) {
      this.year = year;
      this.month = month - 1;

      if (this.year === this.currentYear && this.month === this.currentMonth) {
        this.date = date;
      } else {
        this.date = 1;
      }

      this.disabled = false;
      this.list = [];

      this.check();
    },

    init() {
      const now = new Date();

      this.currentYear = now.getFullYear();
      this.currentMonth = now.getMonth();

      this.year = now.getFullYear();
      this.month = now.getMonth();
      // this.month = now.getMonth() + 1;
      this.date = now.getDate();
      // this.date = now.getDate() + 1;

      this.disabled = false;
      this.list = [];

      this.check();
    },
    check() {
      const now = moment(new Date(this.year, this.month, this.date));
      this.loading = true;

      let data;
      if (this.year !== this.currentYear || this.month !== this.currentMonth) {
        data = {
          year: this.year,
          month: this.month + 1
        };
      }

      console.log("data", data);

      checkUseable(data)
        .then(res => {
          console.log("check res", res);
          if (Array.isArray(res) && res.length > 0) {
            this.list = res.map(item => {
              const hisList = Array.isArray(item.hisList)
                ? [...item.hisList]
                : [];

              // && item.completionStatus !== "project_plan_completion_status_delay"
              if (
                !hisList.find(item => item.date === now.format("YYYY-MM-DD"))
              ) {
                if (hisList.length > 0) {
                  const lastProportion = hisList[hisList.length - 1].proportion;
                  hisList.push({
                    date: now.format("YYYY-MM-DD"),
                    proportion: lastProportion
                  });
                } else {
                  hisList.push({
                    date: now.format("YYYY-MM-DD"),
                    proportion: 0
                  });
                }
              }

              return {
                ...item,
                key: item.id,
                id: item.id,

                projectDesignCode: item.projectDesignCode,
                projectName: item.projectName,

                projectId: item.projectId,
                contractId: item.contractId,

                productionUnitName2: item.contract
                  ? item.contract.productionUnitName2
                  : "",
                productionMasterName: item.contract
                  ? item.contract.productionMasterName
                  : "",

                projectAmount: item.projectAmount,
                progress: item.progress,
                remarks: item.remarks,

                hisList,

                disabled: !!item.id, // 没有id的数据就是暂存的数据，可以删 不可以删除，不可以修改进度，可以修改备注

                completionStatus: item.completionStatus,
                delayYear: item.delayYear,
                delayMonth: item.delayMonth
              };
            });
          }
          this.disabled = false;
        })
        .catch(err => {
          console.log("err", err);
          this.disabled = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    save() {
      if (this.list.length === 0) {
        this.$message.error("请选择项目");
        return;
      }

      let errorFlag = false;

      check: for (let i = 0; i < this.list.length; i++) {
        const item = this.list[i];
        let progress = item.progress || 0;

        for (let j = 0; j < item.hisList.length; j++) {
          const proportion = item.hisList[j].proportion;
          if (typeof proportion !== "number") {
            this.$message.error("进度请输入数字");
            errorFlag = true;
            break check;
          } else {
            const sum = proportion + progress;
            if (sum > 100) {
              this.$message.error(
                item.projectName + "计划进度加累计项目进度不得超过100%"
              );
              errorFlag = true;
              break check;
            }
          }
        }
      }

      if (errorFlag) {
        return;
      }

      console.log("this.list", this.list);

      const now = moment(new Date(this.year, this.month, this.date));

      this.saveLoading = true;
      add({
        year: parseInt(now.format("YYYY")),
        month: parseInt(now.format("M")),
        type: "projectPlanMonth",
        planSubList: this.list
      })
        .then(() => {
          this.$close(this.$route.path);
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },

    saveDraft() {
      if (this.list.length === 0) {
        this.$message.error("请选择项目");
        return;
      }

      let errorFlag = false;

      check: for (let i = 0; i < this.list.length; i++) {
        const item = this.list[i];
        let progress = item.progress || 0;

        for (let j = 0; j < item.hisList.length; j++) {
          const proportion = item.hisList[j].proportion;
          if (typeof proportion !== "number") {
            this.$message.error("进度请输入数字");
            errorFlag = true;
            break check;
          } else {
            const sum = proportion + progress;
            if (sum > 100) {
              this.$message.error(
                item.projectName + "计划进度加累计项目进度不得超过100%"
              );
              errorFlag = true;
              break check;
            }
          }
        }
      }

      if (errorFlag) {
        return;
      }

      console.log("this.list", this.list);

      const now = moment(new Date(this.year, this.month, this.date));

      this.saveDraftLoading = true;
      addDraft({
        year: parseInt(now.format("YYYY")),
        month: parseInt(now.format("M")),
        type: "projectPlanMonth",
        planSubList: this.list
      }).finally(() => {
        this.saveDraftLoading = false;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.center {
  padding: 80px 0;
}
</style>
